import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import AuthorDescription from '../blog/author-description';
import AuthorSocialIcons from '../blog/author-social-icons';

function Signature() {
  return (
    <div className="d-block d-md-flex justify-content-start mt-9">
      <div className="mr-5 text-center">
        <Link to="/about/">
          <StaticImage
            className="mr-3 photo-rounded blog-post-author-photo"
            src="../assets/img/pandev-photo.jpg"
            width={300}
            height={300}
            alt="Adrian Pandev Photo"
          />
        </Link>
      </div>

      <div>
        <h3 className="text-bold text-dark mb-3">
          <Link to="/about/">Adrian Pandev</Link>
        </h3>
        <p className="text-lead text-grey mb-3">
          <AuthorDescription />
        </p>

        <AuthorSocialIcons />
      </div>
    </div>
  );
}

export default Signature;
