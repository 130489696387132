import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

function Reviewer() {
  return (
    <span className="mb-3 text-small blog-meta text-grey no-hover-link d-flex align-items-center">
      <span>Author:&nbsp;</span>
      <StaticImage
        className="mx-3 photo-rounded"
        src="../../assets/img/pandev-photo.jpg"
        width={32}
        height={32}
        alt="Adrian Pandev Photo"
      />
      <Link
        to="/about/"
        className="text-small blog-meta text-grey no-hover-link d-flex align-items-center">
        <strong>Adrian Pandev</strong>
      </Link>
    </span>
  );
}

export default Reviewer;
